<template >
	<v-img
		class="d-flex justify-center align-center"
		max-width="100%"
		max-height="100vh"
	>
		<v-card outlined elevation="9" width="450" class="pa-9 mx-auto">
			<v-card-text>
<!--				<v-img-->
<!--					contain-->
<!--					:src="require('../assets/images/cabr.png')"-->
<!--					max-width="270"-->
<!--					max-height="60"-->
<!--				></v-img>-->

				<div
					class="font-title text-center my-12"
					style="
						font-size: 1.8rem;
						letter-spacing: 0.5rem;
						padding-left: 0.5rem;
					"
				>
					供暖电力化管理平台
				</div>
				<div class="text-h6 font-weight-bold">欢迎登录</div>

				<v-text-field
					class="mt-6"
					label="用户名"
					v-model="userinfo.username"
				>
				</v-text-field>
				<v-text-field
					label="密码"
					v-model="userinfo.password"
					:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
					:type="show ? 'text' : 'password'"
					name="input-10-1"
					@click:append="show = !show"
				>
				</v-text-field>
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn color="primary" @click.native.prevent="submit"
					>登录
					<v-icon>mdi-login</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-img>
</template>

<script>
export default {
	name: "Login",
	data() {
		return {
			userinfo: {
				username: "admin",
				password: "admin@123",
			},
			show: false,
		};
	},

	created() {},

	watch: {},

	components: {},

	computed: {},

	methods: {
		submit() {
			// this.$router.push({ name: "Dashboard" });
      if (this.userinfo.username !== "" && this.userinfo.password !== ""){
        this.$store.dispatch("Login", this.userinfo).then().catch();
      } else {
        this.$message.error("用户名与密码不能为空")
      }
		},
		clear() {
			this.userinfo.username = "";
			this.userinfo.password = "";
		},
	},
	async mounted() {},
};
</script>

<style scoped>
</style>